import * as React from "react"
import {ArrowSmRightIcon} from "@heroicons/react/outline"
import parse from 'html-react-parser';

function CommInfo(props){
    return (
        <div className="bg-gray-200 full-width py-10">
            <h2 className="m-auto px-10 md:px-20 lg:px-24 xl:px-40 text-3xl font-black">{parse(props.title)}</h2>
            <div className = "px-10 md:px-20 lg:px-24 xl:px-40 my-6 text-small font-sans">{parse(props.description)}</div>
            <div className = "m-auto px-10 md:px-20 lg:px-24 xl:px-40 pt-6 text-small font-sans font-bold grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 items-center">
            {props.links.map((links) => (
                    <a className="p-8 text-center bg-gray-100 rounded-md shadow-light h-full align-middle flex justify-center items-center hover:shadow" href={parse(links.metadata.link)} rel="noreferrer" target="_blank">{parse(links.metadata.link_text)}</a>
            ))}
            </div>
        </div>
    )

}
export default CommInfo