import * as React from "react"
import { Link } from "gatsby"

function VideoCard(props) {
  return (
    <Link to={props.link}>
    <div className={props.width + " flex flex-col max-w-72 group"}>
      <img className="shadow transition-shadow group-hover:shadow-dark" src={props.thumbnail} alt={props.title}/>
      <div className="grid grid-col-2 mt-2">
        <Link to={props.link} replace className="text-md font-bold leading-6 col-span-2">{props.title}</Link>
        <p className="text-left text-gray-500">{props.date}  |  {props.time}</p>
      </div>
    </div>
    </Link>
  )
}

export default VideoCard
