import React from "react"
import { Swiper } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import SwiperCore, { Mousewheel, Pagination, Navigation } from "swiper";

SwiperCore.use([Mousewheel, Pagination, Navigation]);

function VideoSlider(props) {

  return (
    <div className={props.background + " full-width py-6"}>
      <h2 className="m-auto px-10 md:px-20 lg:px-24 xl:px-40 mt-6 mb-2 text-4xl font-extrabold">
        {props.sliderTitle}
      </h2>
      <div>
      <Swiper
        direction={"horizontal"}
        navigation={true}
        slidesPerView='auto'
        spaceBetween={30}
        mousewheel={false}
        pagination={false}
        observer={true}
        centeredSlidesBounds={true}
        className="mySwiper"
      >
        {props.children}
        </Swiper>
        <div className="pb-8 pt-8 px-10 md:hidden overflow-x-auto grid gap-6 grid-flow-col hide-scroll">
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default VideoSlider
