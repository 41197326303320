import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import VideoCard from "../components/video-card"
import VideoSlider from "../components/video-slider"
import { graphql } from "gatsby"
import CommInfo from "../components/comm-info"
import { ArrowNarrowRightIcon } from "@heroicons/react/outline"
import { Link } from "gatsby"
import Live from "../components/live/live"
import { SwiperSlide } from "swiper/react"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Home" />
      <Live />
      <VideoSlider sliderTitle="Recent">
        {data.allVimeoVideo.nodes.map(video => (
          <SwiperSlide>
            <VideoCard
              width="w-72"
              thumbnail={video.thumbnail}
              title={video.title}
              link={video.id}
              time={video.duration}
              date={video.date}
            />
          </SwiperSlide>
        ))}
        <SwiperSlide>
          <Link to="/videos">
            <div className="w-72 h-40 mr-40 flex bg-hctv-green justify-center items-center shadow hover:shadow-dark">
              <Link
                to="/videos"
                className="flex flex-nowrap justify-center items-center"
              >
                <h2 className="text-2xl text-white font-black">View All</h2>
                <ArrowNarrowRightIcon className="w-8 pl-2 text-white" />
              </Link>
            </div>
          </Link>
        </SwiperSlide>
      </VideoSlider>
      <VideoSlider sliderTitle="Sports" background="bg-hctv-navy text-white">
        {data.sports.nodes.map(video => (
          <SwiperSlide>
            <VideoCard
              width="w-72"
              thumbnail={video.thumbnail}
              title={video.title}
              link={video.id}
              time={video.duration}
              date={video.date}
            />
          </SwiperSlide>
        ))}
        <SwiperSlide>
          <Link to="/shows/4989434">
            <div className="w-72 mr-40 h-40 flex bg-hctv-green justify-center items-center shadow hover:shadow-dark">
              <Link
                to="/shows/4989434"
                className="flex flex-nowrap justify-center items-center"
              >
                <h2 className="text-2xl text-white font-black text-center">
                  View All
                </h2>
                <ArrowNarrowRightIcon className="w-8 pl-2 text-white" />
              </Link>
            </div>
          </Link>
        </SwiperSlide>
      </VideoSlider>
      <CommInfo
        title={data.cosmicjsCommunity.title}
        description={data.cosmicjsCommunity.content}
        links={data.allCosmicjsCommunityLinks.nodes}
      />
    </Layout>
  )
}

export const query = graphql`
  {
    allVimeoVideo(
      filter: { showcase: { elemMatch: { name: { ne: "Sports" } } } }
      limit: 10
    ) {
      nodes {
        id
        thumbnail
        title
        duration
        date
      }
    }
    sports: allVimeoVideo(
      filter: { showcase: { elemMatch: { name: { eq: "Sports" } } } }
      limit: 10
    ) {
      nodes {
        id
        thumbnail
        title
        duration
        date
      }
    }
    cosmicjsCommunity {
      content
      title
    }
    allCosmicjsCommunityLinks {
      nodes {
        metadata {
          link
          link_text
        }
      }
    }
  }
`

export default IndexPage
