import * as React from "react"
import CommunityThumbnail from "../../images/live/main-street.webp"
import PublicThumbnail from "../../images/live/Document.webp"
import InfoThumbnail from "../../images/live/clocktower.webp"
import { Circle } from "@styled-icons/fa-solid"
import { Link } from "gatsby"

function Live() {
  return (
    <div className="full-width">
      <div className="grid grid-flow-col gap-6 px-10 pt-8 pb-8 overflow-x-auto hide-scroll md:px-20 lg:px-24 xl:px-40">
        <Link to="/community">
          <div
            className="flex flex-col justify-between w-full p-4 shadow h-96 group-hover:shadow-dark"
            style={{
              background: `linear-gradient(rgba(16, 30, 43, 0.6), rgba(16, 30, 43, 0.6)), url(${CommunityThumbnail})`,
              backgroundSize: `cover`,
              backgroundPosition: `center`,
            }}
          >
            <div className="flex justify-end">
              <p className="flex items-center justify-center gap-2 px-3 py-1 text-white rounded-full bg-hctv-red">
                <Circle className="w-2" /> Live
              </p>
            </div>
            <div className="px-4 py-8 text-white bg-black bg-opacity-70 rounded-xl">
              <p className="text-lg"></p>
              <h2 className="text-2xl font-black">Channel 1021</h2>
            </div>
          </div>
        </Link>
        {/* <Link to="/public-meetings">
          <div
            className="flex flex-col justify-between w-64 p-4 shadow h-96 lg:w-full lg:h-52"
            style={{
              background: `linear-gradient(rgba(122, 158, 62, 0.6), rgba(122, 158, 62, 0.6)), url(${PublicThumbnail})`,
              backgroundSize: `cover`,
              backgroundPosition: `center`,
            }}
          >
            <div className="flex justify-end">
              <p className="flex items-center justify-center gap-2 px-3 py-1 text-white rounded-full bg-hctv-red">
                <Circle className="w-2" /> Live
              </p>
            </div>
            <div className="p-4 text-white bg-black bg-opacity-70 rounded-xl">
              <p className="text-lg">Ch. 1022</p>
              <h2 className="text-2xl font-black">Government Meetings</h2>
            </div>
          </div>
        </Link>
        <Link to="/community-info">
          <div
            className="flex flex-col justify-between w-64 p-4 shadow h-96 lg:w-full lg:h-52"
            style={{
              background: `linear-gradient(rgba(160, 48, 36, 0.6), rgba(160, 48, 36, 0.6)), url(${InfoThumbnail})`,
              backgroundSize: `cover`,
              backgroundPosition: `center`,
            }}
          >
            <div className="flex justify-end">
              <p className="flex items-center justify-center gap-2 px-3 py-1 text-white rounded-full bg-hctv-red">
                <Circle className="w-2" /> Live
              </p>
            </div>
            <div className="p-4 text-white bg-black bg-opacity-70 rounded-xl">
              <p className="text-lg">Ch. 1023</p>
              <h2 className="text-2xl font-black">Community Information</h2>
            </div>
          </div>
        </Link> */}
      </div>
    </div>
  )
}

export default Live
